import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext';
const OrderItem = ({ item, onRemove, onEdit }) => {
    const { toggleCartVisibility, setCartItems, setIsEditingItem, setItemInEdit } = useCart();

    const navigate = useNavigate();


    const handleEdit = (item, index) => {
        toggleCartVisibility();
        setIsEditingItem(true);
        setItemInEdit(item);
        navigate('/');
    };

    const removeItemFromCart = (item, index) => {
        setCartItems(currentItems =>
            currentItems
                .filter((_, itemIndex) => itemIndex !== index) // First, remove the item
                .map((currentItem, itemIndex) =>
                    currentItem.itemIndex >= index ? { ...currentItem, itemIndex: currentItem.itemIndex - 1 } : currentItem // Adjust index if needed
                )
        );
    };

    return (
        <>
            <div className="flex justify-between items-center py-2">
                <div>
                    <p className="text-sm font-medium">{item.quantity} x {item.name}</p>
                    {
                        item.selectedOptions && (
                            <div >{Object.entries(item.selectedOptions).map(([key, value]) => (
                                <p key={key} className="text-sm text-gray-500">
                                    {`${key}: ${value.map((v) => v.name).join(', ')}`}
                                </p>
                            ))} </div>
                        )
                    }
                </div>
                <span className="text-sm font-semibold">${item.calculatedPrice.toFixed(2)}</span>

            </div>
            <div className="flex mt-2">
                <button onClick={() => handleEdit(item, item.itemIndex)} className="text-indigo-600 hover:text-indigo-800 mr-4 text-sm">Edit</button>
                <button onClick={() => removeItemFromCart(item, item.itemIndex)} className="text-indigo-600 hover:text-indigo-800 text-sm">Remove</button>
            </div></>
    );
};

const OrderItems = ({ items }) => {
    return (<>{items.map((item, index) => (
        <OrderItem key={index} item={item} />
    ))}</>)
}

const OrderDetails = () => {
    const { cartItems, totalItems } = useCart();
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const handleAddMoreItems = () => {
        navigate('/');
    }


    return (
        <div className="max-w-2xl mx-auto ">
            <div className="flex justify-between items-center border-t border-gray-200 py-2">
                <h3 className="text-lg font-semibold">Order details ({totalItems})</h3>
                <button onClick={() => setIsOpen(!isOpen)} className="text-indigo-600 hover:text-indigo-800 transition ease-in-out duration-150">
                    <svg className={`w-6 h-6 transform ${isOpen ? 'rotate-180' : ''}`} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7"></path></svg>
                </button>
            </div>
            <div className="border-b">
                {isOpen && (
                    <>
                        <OrderItems items={cartItems} />

                        <div className="flex justify-center">
                            <button className="flex border-none items-center text-lg text-indigo-600 hover:text-indigo-800 py-2 px-4 border border-indigo-600 rounded" onClick={handleAddMoreItems}>
                                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7"></path>
                                </svg>
                                Add more items
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};


export {
    OrderDetails,
    OrderItems,
}