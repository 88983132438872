// Modal.tsx
import React, { useState, useEffect } from 'react';
import './Modal.css';
import { useCart } from '../CartContext';
import { MdClose } from "react-icons/md";
import { SelectedOptions, CartItem } from "../types/DishTypes";
import { useRestaurantStore } from '../api/graphqlClient';
import { LazyMenuItemOption, LazyMenuSection, MenuItemOption, MenuItemOptionSelection } from '../models';
interface ModalProps {
    item: CartItem;
    onClose: () => void;
}
const Modal: React.FC<ModalProps> = ({ item, onClose }) => {
    const { isCartVisible, toggleCartVisibility, closeCart, cartItems, setCartItems, isEditingItem, setIsEditingItem, itemInEdit, setItemInEdit, updateCart, handleAddToCart, total } = useCart();
    const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({});
    const [menuItemOptions, setMenuItemOptions] = useState<any>([]);


    const [quantity, setQuantity] = useState<number>(1);
    const [cartItemTotalPrice, setCartItemTotalPrice] = useState<number>(0);
    const [missingRequiredSelections, setMissingRequiredSelections] = useState<Set<string>>(new Set());
    const { restaurant, menuItems, menuItemsOptions, fetchMenuSections, fetchMenuItem, loading, error } = useRestaurantStore();


    useEffect(() => {
        const loadData = async () => {
            await fetchMenuItem(item.id)
        };
        loadData();
    }, [item])

    useEffect(() => {
        const loadData = async () => {
            if (menuItems[item.id]) {
                setMenuItemOptions(menuItemsOptions[item.id]);
            }
        };
        loadData();
    }, [menuItemsOptions])


    useEffect(() => {
        if (isEditingItem && itemInEdit) {
            setQuantity(itemInEdit.quantity);
            setSelectedOptions(itemInEdit.selectedOptions);
            setMissingRequiredSelections(new Set<string>());
        }
    }, [itemInEdit]);

    useEffect(() => {
        const missingRequired = new Set<string>();
        if (!isEditingItem) {
            menuItemOptions.forEach((menuItemOption: MenuItemOption) => {
                if (menuItemOption.requiredSelectionCount ?? 0 > 0) {
                    missingRequired.add(menuItemOption.name ?? "");
                }
            });
            setMissingRequiredSelections(missingRequired);
        }
    }, [menuItemOptions]);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            const modalOverlay = document.querySelector('.modal-overlay') as HTMLElement;
            const modalContent = document.querySelector('.modal-content') as HTMLElement;

            if (modalOverlay && modalContent && !modalContent.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [onClose]);

    useEffect(() => {
        // Re-calculate cart item total price when selectedOptions or quantity changes
        const optionsCost = Object.values(selectedOptions).reduce((acc, options) => acc + options.reduce((optionAcc, option) => optionAcc + (option.additionalCost || 0), 0), 0);
        const total = ((item.price ?? 0) + optionsCost) * quantity;
        setCartItemTotalPrice(total);
    }, [selectedOptions, quantity, item.price]); // Make sure to include all dependencies


    const handleOptionSelect = (
        categoryName: string,
        optionName: string,
        additionalCost: number = 0,
        requiredSelectionCount: number,
        maximumSelectionCount: number,
        event: React.MouseEvent // Add event parameter to capture the click event
    ) => {
        setSelectedOptions((prev) => {
            const existingSelections = prev[categoryName] || [];
            // If the current selection is the same as the one being double clicked, remove the selection
            var isAlreadySelected = false;
            var newSelections: MenuItemOptionSelection[] = [];
            existingSelections.map((categorySelection, index) => {
                if (categorySelection.name === optionName) {
                    newSelections = [...existingSelections.slice(0, index), ...existingSelections.slice(index + 1)];
                    isAlreadySelected = true;
                }
            })

            if (isAlreadySelected) {
                const newState = { ...prev, [categoryName]: newSelections };
                if (requiredSelectionCount > 0 && newSelections.length < requiredSelectionCount) {
                    setMissingRequiredSelections((prev) => {
                        const newMissing = new Set(prev);
                        newMissing.add(categoryName);
                        return newMissing;
                    });
                }

                return newState;
            }
            var newState;
            var newSelections: MenuItemOptionSelection[];
            if (existingSelections.length < maximumSelectionCount) {
                newSelections = [...existingSelections, { name: optionName, additionalCost: additionalCost }];
                newState = {
                    ...prev,
                    [categoryName]: newSelections,
                }
                if (requiredSelectionCount > 0 && newSelections.length === requiredSelectionCount) {
                    setMissingRequiredSelections((prev) => {
                        const newMissing = new Set(prev);
                        newMissing.delete(categoryName);
                        return newMissing;
                    });
                }
                return newState;
            } else {
                return prev;
            }
        });
    };


    const handleAddToCartClick = () => {
        const itemToAdd = {
            id: item.id,
            name: item.name,
            price: item.price,
            imageUrl: item.imageUrl ?? `https://quicksnackz-static-images.s3.us-west-1.amazonaws.com/item-${item.id}.png`,
            quantity,
            // optionCategories: item.optionCategories,
            optionCategories: null,
            selectedOptions,
            calculatedPrice: cartItemTotalPrice,
            itemIndex: isEditingItem ? itemInEdit.itemIndex : cartItems.length,
        };
        if (isEditingItem) {
            setIsEditingItem(false);
            toggleCartVisibility();
            updateCart(itemToAdd);
        } else {
            handleAddToCart(itemToAdd);
        }
        onClose();
    };

    const isSelectedOption = (categoryName: string, optionName: string) => {
        const selectedOptionNames = new Set(selectedOptions[categoryName]?.map((option) => option.name));
        return selectedOptionNames.has(optionName);
    }

    const buttonShouldBeDisabled = (category: MenuItemOption, option: MenuItemOptionSelection) => {

        const selectedOptionNames = new Set(selectedOptions[category.name ?? ""]?.map((option) => option.name));

        return (category.maximumSelectionCount ?? 0) > 0 && selectedOptionNames.size === category.maximumSelectionCount && !selectedOptionNames.has(option.name);
    }


    const handleQuantityChange = (delta: number) => {
        setQuantity((prevQuantity: number) => Math.max(1, prevQuantity + delta));
    };

    const instructions = (category: MenuItemOption) => {

        const min = category.requiredSelectionCount;
        const max = category.maximumSelectionCount;
        const uptoClause = max ?? 0 > 0 ? `up to ${max}` : ""
        const minClause = min ?? 0 > 0 ? `at least ${min}` : ""
        if (max === min) {
            return `Please select ${min}`
        }
        return `Please select ${minClause} ${uptoClause}`
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
            <div className="relative w-full h-full bg-gray-600 bg-opacity-55 flex justify-center items-center z-50">
                <div className="relative bg-white rounded-3xl lg:w-1/2 md:w-1/2 sm:w-full h-4/5 overflow-hidden flex flex-col">
                    <div className='absolute top-4 right-4 rounded-full bg-white w-8 h-8 flex justify-center items-center'>
                        <button onClick={onClose} className="text-xl ">
                            <MdClose />
                        </button>
                    </div>
                    <div className="w-full overflow-auto custom-scrollbar">

                        <img src={item.imageUrl ?? `https://quicksnackz-static-images.s3.us-west-1.amazonaws.com/item-${item.id}.png`} className="w-full object-cover h-1/2 " />
                        <div className="p-4 hide-scrollbar">

                            <h3 className="text-2xl font-bold text-left mt-4">{item.name}</h3>

                            <p className="mt-4 text-gray-500 hide-scrollbar">{item.description}</p>
                            {menuItemOptions.map((menuItemOption: MenuItemOption, index: number) => (
                                <div key={menuItemOption.id + index} className="my-4 border-t-2 py-2">
                                    <h4 className="text-lg font-semibold">{menuItemOption.name}</h4>
                                    <p className="text-sm text-gray-500">{instructions(menuItemOption)} </p>
                                    <div className="mt-2 flex flex-wrap ">
                                        {(menuItemOption.selections ?? [])
                                            .filter((selection): selection is MenuItemOptionSelection => selection !== null)
                                            .map((selection: MenuItemOptionSelection, index) => (
                                                <div key={menuItemOption.id + index} className='px-1 w-1/2'>
                                                    <button
                                                        key={menuItemOption.id + selection.name}
                                                        className={
                                                            "flex justify-between flex-wrap text-md w-full py-2 px-4 rounded-lg shadow-sm mt-2 border " +
                                                            (isSelectedOption(menuItemOption.name ?? "", selection.name ?? "") ? 'border-blue-500 ' : 'border-gray-300 ') +
                                                            (buttonShouldBeDisabled(menuItemOption, selection) ? 'opacity-50 text-gray-300 cursor-not-allowed' : 'md:hover:border-blue-500')
                                                        }
                                                        onClick={(e) =>
                                                            handleOptionSelect(
                                                                menuItemOption.name ?? "",
                                                                selection.name ?? "",
                                                                selection.additionalCost || 0,
                                                                menuItemOption.requiredSelectionCount ?? 0,
                                                                menuItemOption.maximumSelectionCount ?? 0,
                                                                e
                                                            )
                                                        }
                                                        disabled={buttonShouldBeDisabled(menuItemOption, selection)}
                                                    >
                                                        <p>{selection.name}</p>
                                                        <p>{selection.additionalCost ? `+ $${selection.additionalCost} ` : ''}</p>
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='h-20 p-4 border-t-2'>
                        <div className="flex justify-between items-center ">
                            <div className="flex items-center space-x-2">
                                <button onClick={() => handleQuantityChange(-1)}
                                    className=" bg-white w-4 h-4 flex items-center justify-center focus:outline-none"
                                    disabled={quantity === 1}
                                >
                                    -
                                </button>
                                <div className=" py-1 px-6 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-3xl text-sm md:text-base lg:text-lg xl:text-xl">{quantity}</div>
                                <button
                                    onClick={() => handleQuantityChange(1)}
                                    className="bg-white rounded-full w-4 h-4 flex items-center justify-center focus:outline-none"
                                >
                                    +
                                </button>
                            </div>
                            <button
                                onClick={() => { handleAddToCartClick(); onClose() }}
                                className="bg-blue-500 disabled:opacity-100 disabled:text-gray-300 disabled:bg-gray-500 rounded-3xl text-white px-5 py-2 focus:outline-none items-center w-1/2 hover:bg-blue-700"
                                disabled={missingRequiredSelections.size > 0}
                            >
                                <div className='flex justify-between text-sm md:text-base lg:text-lg xl:text-xl font-semibold'>
                                    <p className=''>{isEditingItem ? "Update Cart" : "Add To Cart"}</p>
                                    <div className="">${cartItemTotalPrice.toFixed(2)}</div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );
};

export default Modal;
