import React from 'react';
import { useUser } from '../UserContext';
import { CgProfile } from "react-icons/cg";
import { MdOutlineEmail, MdLocalPhone } from "react-icons/md";

const UserInfo = () => {
    const { firstName, lastName, email, phone } = useUser(); // Assuming useUser returns directly name, email, and phone

    return (


        <div className="max-w-2xl mx-auto ">

            <div className="max-w-2xl mx-auto">
                <div className="flex justify-between items-center py-2">
                    <h3 className="text-lg font-semibold">Your info</h3>
                </div>
            </div>

            <div className="max-w-2xl mx-auto flex flex-col space-y-2 ">
                <div className="flex items-center space-x-2 text-gray-700">
                    <CgProfile className="text-lg" />
                    <span>{firstName} {lastName}</span>
                </div>
                <div className="flex items-center space-x-2 text-gray-700">
                    <MdOutlineEmail className="text-lg" />
                    <span>{email}</span>
                </div>
                {/* <div className="flex items-center space-x-2 text-gray-700">
                    <MdLocalPhone className="text-lg" />
                    <span>{phone}</span>
                </div> */}
                <div className="border-b border-gray-300"></div>
            </div>
        </div>

    );
};

export default UserInfo;
