// src/components/HomePage.js

import React, { useEffect, useRef, useState, RefObject } from "react";

import Navigation from './Navigation';
import MenuSectionComponent from './MenuSection';
import dishExamples from "../DishExamples";
import Header from './Header';
import { useCart } from '../CartContext';
import { useUser } from '../UserContext';
import Modal from './Modal';
import LoginModal from "./LoginModal";
import { get, post } from 'aws-amplify/api';
import { useRestaurantStore } from '../api/graphqlClient';
// import { fetchMenuSections, fetchMenuItem } from '../api/graphqlClient';
import { MenuSection, MenuItem } from "../models";


const showModel = (selectedDish: any, isEditingItem: boolean) => {
    return (selectedDish || isEditingItem);
}

function HomePage() {
    // Assuming the sections data is imported or defined here
    const { isCartVisible, toggleCartVisibility, closeCart, isCheckoutOpen, cartItems, setCartItems, isEditingItem, setIsEditingItem, itemInEdit } = useCart();
    // Add state for the login modal visibility

    const { user, setUser, firstName, lastName, email, setEmail, phone, setPhone, isLoggedIn, showLoginModal, setShowLoginModal } = useUser();
    const [open, setOpen] = useState(false);
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState<any>(null);

    const [sectionRefs, setSectionRefs] = useState<RefObject<HTMLOptionElement>[]>([]);
    const [selectedDish, setSelectedDish] = useState<MenuItem | null>();
    const { menuSections, restaurant, fetchRestaurant, fetchMenuSections, fetchMenuItem, loading, error } = useRestaurantStore();

    useEffect(() => {
        const loadData = async () => {
            await fetchRestaurant("kin-len");
            if (restaurant?.id) {
                await fetchMenuSections(restaurant.id);
            }
        };
        loadData();
    }, [restaurant]);

    useEffect(() => {
        setSectionRefs(menuSections.map(() => React.createRef()));
    }, [menuSections]);



    // Function to scroll to a ref
    const scrollToRef = (index: number, offset: number) => {
        // Ensure that offset is subtracted from the calculated target position
        const targetPosition = (sectionRefs[index]?.current?.offsetTop ?? 0) - offset;
        window.scrollTo({ top: targetPosition, behavior: 'smooth' });
    };



    // Call this function when a dish is clicked
    const handleDishSelect = (dish: any) => {

        setSelectedDish(dish);
    };

    // Call this function to close the modal
    const handleCloseModal = () => {
        setSelectedDish(null);
        setIsEditingItem(false)
    };

    if (isCheckoutOpen) {
        return null;
    }
    return (
        <div >

            <Header />
            <Navigation sections={menuSections} scrollToRef={scrollToRef} menuSectionRefs={sectionRefs} />
            <div className="content-below-nav">
                {menuSections.length > 0 && menuSections.map((menuSection: any, index: number) => (
                    menuSection.menuItems &&
                    <MenuSectionComponent
                        key={index}
                        ref={sectionRefs[index]}
                        id={index.toString()}
                        title={menuSection.name}
                        items={menuSection.menuItems.items}
                        onItemSelect={handleDishSelect}
                    />
                ))}
            </div>
            {showModel(selectedDish, isEditingItem) && (
                <Modal
                    item={selectedDish || itemInEdit}
                    onClose={handleCloseModal}
                />
            )}
            {
                // This is a simple implementation. Replace with your Modal content or Component
                showLoginModal && <LoginModal ></LoginModal>
            }
        </div>
    );
}

export default HomePage;
