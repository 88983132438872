
import React, { createContext, useState, useContext, useEffect } from 'react';

import { useCart } from '../CartContext';
import './Checkout.css';
import { Route, Navigate } from 'react-router-dom';
import './Cart.css'
import { OrderDetails } from "./OrderDetails";
import { useUser } from '../UserContext';
import UserInfo from "./UserInfo"
import Payment from "./Payment";
import { CheckoutSummary } from "./CheckoutSummary";
import GoogleMapEmbed from "./GoogleMapEmbed";
import RestaurantInfo from "./RestaurantInfo";

const Checkout = () => {
  const { isLoggedIn } = useUser();
  const { cartItems } = useCart();

  const shouldNavigate = () => {
    return cartItems.length === 0 || !isLoggedIn;
  };

  return (
    !shouldNavigate() ? (
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded-lg overflow-hidden">
        <div className="px-12 py-4">
          <RestaurantInfo />
          <GoogleMapEmbed />
          <OrderDetails />
          <UserInfo />
          <Payment />
          <CheckoutSummary />
        </div>
      </div>) : <Navigate to="/" />
  );
};

export default Checkout;
