import React, { useEffect, useState } from 'react';
import { useCart } from '../CartContext';

const TotalAmountSummary = ({ subtotal, tax, tipAmount, totalAfterTip }) => {
    return (
        <div className="flex-row ">
            <div className="mb-2 flex justify-between">
                <span className="text-gray-600">Subtotal</span>
                <span className="ml-4 font-semibold">${subtotal.toFixed(2)}</span>
            </div>
            <div className="mb-2 flex justify-between">
                <span className="text-gray-600">Tax</span>
                <span className="ml-4 font-semibold">${tax.toFixed(2)}</span>
            </div>
            {tipAmount != "0" && (<div className="mb-2 flex justify-between">
                <span className="text-gray-600">Tip</span>
                <span className="ml-4 font-semibold">${tipAmount || "0"}</span>
            </div>)}

            <div className="mb-2 flex justify-between">
                <span className="mb-2 flex justify-between font-bold ">Total</span>
                <span className="ml-4 font-semibold">${totalAfterTip.toFixed(2)}</span>
            </div>
        </div>)
}
const CheckoutSummary = () => {
    const { isCheckoutOpen, cartItems, subtotal, total, tax } = useCart();

    const [tipAmount, setTipAmount] = useState("0");
    const [selectedTipButton, setSelectedTipButton] = useState();
    const [totalAfterTip, setTotalAfterTip] = useState(total);
    const [showCustomTip, setShowCustomTip] = useState(false);

    // const customTip = (<div className="border-b border-gray-300"></div>)
    useEffect(() => {
        setTotalAfterTip(subtotal + tax + (parseFloat(tipAmount) || 0));
    }, [tipAmount, subtotal, tax]);



    const handleTipChange = (e) => {
        const percentage = e.target.innerText;
        if (percentage === 'Other') {
            setShowCustomTip(true);
            setTipAmount("");
            setSelectedTipButton("Other");
        } else {
            setSelectedTipButton(percentage);
            setTipAmount(`${(subtotal * parseInt(percentage) / 100).toFixed(2)}`)
        }
    }

    const isSelected = (percentage) => {
        return percentage === selectedTipButton;
    }

    const handleCustomTipChange = (e) => {
        const value = e.target.value;
        // Allow numbers and a single dot for decimal input
        const re = /^[0-9]*\.?[0-9]*$/;
        if (re.test(value)) {
            setTipAmount(value);
        }
    };

    return (
        <div className="py-2">
            <h2 className="text-lg font-semibold mb-4">Add a tip</h2>
            <div className="flex mb-4 space-x-1">
                {/* Tip buttons */}
                {['18%', '20%', '22%', '25%', 'Other'].map((percentage, index) => (
                    <button
                        key={index}
                        onClick={handleTipChange}
                        className={`flex-grow py-1 border rounded ${isSelected(percentage) ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                        {percentage}
                    </button>
                ))}

            </div>
            {selectedTipButton === 'Other' && (
                <div className="mb-4">
                    <label htmlFor="custom-tip" className="text-gray-700"></label>
                    <input
                        id="custom-tip"
                        type="text"
                        value={tipAmount}
                        onChange={handleCustomTipChange}
                        className="mt-1 w-full p-2 text-center border-none text-gray-700"
                        placeholder="Tip Amount"
                    />
                </div>
            )}

            <TotalAmountSummary subtotal={subtotal} tax={tax} tipAmount={tipAmount} totalAfterTip={totalAfterTip} />




            {/* Place order button */}
            <button className="w-full bg-blue-500 text-white py-3 rounded-full mt-6">
                Place Order
            </button>

            {/* Disclaimer text */}
            <p className="text-xs text-gray-600 mt-4">
                By completing your order, you give QuickSnackz and this restaurant or restaurant group permission to send you digital receipts, order updates powered by QuickSnackz, and marketing materials. Info for CA residents: available here. Message frequency varies and message and data rates may apply. Reply STOP to opt out. Program is subject to the QuickSnackz Guest Terms of Service and QuickSnackz's Privacy Statement.
            </p>
        </div >
    );
};

export { CheckoutSummary, TotalAmountSummary };
