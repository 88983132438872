// Cart.jsx
import React, { useState, useEffect } from 'react';
import { useCart } from '../CartContext';
import { useUser } from '../UserContext';
import './Cart.css';
import { ImCross } from "react-icons/im";
import { useNavigate } from 'react-router-dom';
import { OrderDetails, OrderItems } from './OrderDetails';
import { TotalAmountSummary } from './CheckoutSummary';

const Cart = () => {
    const { isCartVisible, toggleCartVisibility, closeCart, cartItems, setCartItems, isEditingItem, setIsEditingItem, itemInEdit, setItemInEdit, subtotal, tax, total } = useCart();
    const [cartRef, setCartRef] = useState(null);
    const navigate = useNavigate();
    const { isLoggedIn, showLoginModal, setShowLoginModal, openUserNotLoggedInModal, checkIfUserIsLoggedIn, promptUsertoLoginIfNecessary } = useUser();


    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (cartRef && !cartRef.contains(event.target)) {
                // Clicked outside the cart, close it
                closeCart();
            }
        };

        if (isCartVisible) {
            // Attach event listener when the cart is visible
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            // Detach event listener when the component is unmounted or the cart is hidden
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isCartVisible, closeCart, cartRef]);


    // Inside CartContext's provider or a similar method for updating cart items
    const handleCheckoutClick = () => {
        if (promptUsertoLoginIfNecessary()) {
            closeCart();
            navigate('/checkout');
        } else {
            setShowLoginModal(true);
        }
    }

    return (
        <>
            {isCartVisible && (
                <div ref={(ref) => setCartRef(ref)} className="cart-modal px-4 py-2">
                    <div className="cart-header ">
                        <h3 className="text-lg font-semibold">Order details ({cartItems.length})</h3>
                        <ImCross className="cart-close-button" onClick={closeCart} />
                    </div>
                    <div >
                        {cartItems.length === 0 ? (
                            <div className="cart-empty">
                                <p>Add items to start your order.</p>
                            </div>
                        ) : (
                            <div >
                                <div className='border-b py-2'>
                                    <OrderItems items={cartItems} />
                                </div>
                                <div className='py-2'>
                                    <TotalAmountSummary subtotal={subtotal} tax={tax} tipAmount={0} totalAfterTip={total} />
                                </div>
                                <button className="checkout-button" onClick={handleCheckoutClick}>Checkout</button>
                            </div>
                        )}
                    </div>
                </div >
            )}
        </>
    );
};

export default Cart;
