// Item.jsx
import React, { useState, useEffect } from 'react';
import "./Item.css";

const Item = ({ item, items, onItemSelect }) => {
    const minItemWidth = 400; // Mininum width of an item if there are multiple in a row
    const itemPadding = 50; // Note: Careful with updating this value, it is a combination of all the paddings/margins until this div
    const [itemWidth, setItemWidth] = useState(minItemWidth); // Set a default width for the items


    const adjustItemWidth = () => {
        const containerWidth = document.getElementById('items-container').clientWidth;
        if (containerWidth < minItemWidth) {
            setItemWidth(containerWidth - itemPadding)
            return;
        }
        const itemsPerRow = Math.min(items.length, Math.floor(containerWidth / (minItemWidth + itemPadding)));
        const availableWidth = containerWidth - (itemsPerRow * itemPadding);
        const newItemWidth = Math.floor(availableWidth / itemsPerRow);
        setItemWidth(newItemWidth);
    };


    useEffect(() => {
        adjustItemWidth();
        // Add an event listener for window resize
        window.addEventListener('resize', adjustItemWidth);
        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', adjustItemWidth);
    }, [items]); // Re-run the effect when the items change

    return (

        <div className="lg:w-1/3 md:w-1/2 sm:w-full flex p-4">
            <div className="group w-full " onClick={() => onItemSelect(item)}>
                <div className="flex w-full space-x-4 rounded-xl overflow-hidden border shadow-sm transition duration-300 ease-in-out transform group-hover:scale-105 group-hover:shadow-lg">
                    <img
                        src={item.imageUrl}
                        alt={item.name}
                        className="flex-none w-36 h-32 object-cover" // fixed width for image
                    />
                    <div className="flex-1 flex flex-col py-2">
                        <h3 className="font-bold text-lg truncate">{item.name}</h3>
                        <p className="text-gray-900">${item.price}</p>
                        <p className="text-gray-500 text-sm overflow-hidden mt-2">
                            <span className="line-clamp-2">{item.description}</span> {/* Limit to three lines */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Item;
