import React, { createContext, useContext, useEffect, useState } from 'react';
import { signOut, fetchAuthSession } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { message } from 'antd';

interface UserContextValue {
  signOutAction: () => Promise<void>;
  promptUsertoLoginIfNecessary: () => Promise<boolean>;
  user: string;
  setUser: React.Dispatch<React.SetStateAction<string>>;
  firstName: string;
  lastName: string;
  phone: string;
  setPhone: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  showLoginModal: boolean;
  setShowLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserContext = createContext<UserContextValue | null>(null);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("+1(888) 888 888");
  const [email, setEmail] = useState<string>("");
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  const setUserAttributes = async () => {
    try {
      const { tokens } = await fetchAuthSession();
      if (tokens && tokens.idToken) {
        const { idToken } = tokens;

        // Ensure these are always strings
        const givenName = idToken?.payload.given_name ? String(idToken.payload.given_name) : "";
        const familyName = idToken?.payload.family_name ? String(idToken.payload.family_name) : "";
        const email = idToken?.payload.email ? String(idToken.payload.email) : "";

        setEmail(email);
        setFirstName(givenName);
        setLastName(familyName);
        setIsLoggedIn(true);
      }
    } catch (err) {
      console.error("Error fetching user attributes:", err);
    }
  };

  const promptUsertoLoginIfNecessary = async () => {
    if (isLoggedIn) {
      return true;
    } else {
      message.info("Please login first.");
      setShowLoginModal(true); // Optionally show login modal
      return false;
    }
  };

  const signOutAction = async () => {
    await signOut({ global: true });
    setIsLoggedIn(false); // Ensure logged-out state is set
  };

  useEffect(() => {
    setUserAttributes();
    const unsubscribe = Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          setUserAttributes();
          message.success('Logged In!');
          break;
        case "signedOut":
          setIsLoggedIn(false);
          message.success('Logged out!');
          break;
        case "signInWithRedirect_failure":
          // handle sign in failure
          console.log("sign in Failure")
          break;

      }
    });

    return () => unsubscribe();
  }, []);

  const contextValue: UserContextValue = {
    promptUsertoLoginIfNecessary,
    signOutAction,
    user,
    setUser,
    firstName,
    lastName,
    email,
    setEmail,
    phone,
    setPhone,
    isLoggedIn,
    setIsLoggedIn,
    showLoginModal,
    setShowLoginModal,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};
