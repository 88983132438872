/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMenuItem = /* GraphQL */ `
  query GetMenuItem($id: ID!) {
    getMenuItem(id: $id) {
      id
      description
      imageUrl
      menusectionsID
      name
      price
      createdAt
      updatedAt
      menuItemOptions {
        items {
          id
          description
          maximumSelectionCount
          menuitemID
          name
          requiredSelectionCount
          selections {
            additionalCost
            name
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMenuItems = /* GraphQL */ `
  query ListMenuItems(
    $id: ID
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuItems(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        description
        imageUrl
        menusectionsID
        name
        price
        createdAt
        updatedAt
        menuItemOptions {
          items {
            id
            description
            maximumSelectionCount
            menuitemID
            name
            requiredSelectionCount
            selections {
              additionalCost
              name
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMenuItems = /* GraphQL */ `
  query SyncMenuItems(
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenuItems(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        description
        imageUrl
        menusectionsID
        name
        price
        createdAt
        updatedAt
        menuItemOptions {
          items {
            id
            description
            maximumSelectionCount
            menuitemID
            name
            requiredSelectionCount
            selections {
              additionalCost
              name
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMenuItemOption = /* GraphQL */ `
  query GetMenuItemOption($id: ID!) {
    getMenuItemOption(id: $id) {
      id
      description
      maximumSelectionCount
      menuitemID
      name
      requiredSelectionCount
      selections {
        additionalCost
        name
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMenuItemOptions = /* GraphQL */ `
  query ListMenuItemOptions(
    $id: ID
    $filter: ModelMenuItemOptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuItemOptions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        description
        maximumSelectionCount
        menuitemID
        name
        requiredSelectionCount
        selections {
          additionalCost
          name
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMenuItemOptions = /* GraphQL */ `
  query SyncMenuItemOptions(
    $filter: ModelMenuItemOptionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenuItemOptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        description
        maximumSelectionCount
        menuitemID
        name
        requiredSelectionCount
        selections {
          additionalCost
          name
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMenuSection = /* GraphQL */ `
  query GetMenuSection($id: ID!) {
    getMenuSection(id: $id) {
      id
      description
      name
      restaurantID
      createdAt
      updatedAt
      menuItems {
        items {
          id
          description
          imageUrl
          menusectionsID
          name
          price
          createdAt
          updatedAt
          menuItemOptions {
            items {
              id
              description
              maximumSelectionCount
              menuitemID
              name
              requiredSelectionCount
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMenuSections = /* GraphQL */ `
  query ListMenuSections(
    $id: ID
    $filter: ModelMenuSectionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMenuSections(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        description
        name
        restaurantID
        createdAt
        updatedAt
        menuItems {
          items {
            id
            description
            imageUrl
            menusectionsID
            name
            price
            createdAt
            updatedAt
            menuItemOptions {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMenuSections = /* GraphQL */ `
  query SyncMenuSections(
    $filter: ModelMenuSectionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMenuSections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        description
        name
        restaurantID
        createdAt
        updatedAt
        menuItems {
          items {
            id
            description
            imageUrl
            menusectionsID
            name
            price
            createdAt
            updatedAt
            menuItemOptions {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRestaurant = /* GraphQL */ `
  query GetRestaurant($id: ID!) {
    getRestaurant(id: $id) {
      id
      address
      businessHour {
        sun {
          start
          end
          __typename
        }
        mon {
          start
          end
          __typename
        }
        tue {
          start
          end
          __typename
        }
        wed {
          start
          end
          __typename
        }
        thu {
          start
          end
          __typename
        }
        fri {
          start
          end
          __typename
        }
        sat {
          start
          end
          __typename
        }
        __typename
      }
      description
      imageUrl
      name
      rating
      createdAt
      updatedAt
      menuSections {
        items {
          id
          description
          name
          restaurantID
          createdAt
          updatedAt
          menuItems {
            items {
              id
              description
              imageUrl
              menusectionsID
              name
              price
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRestaurants = /* GraphQL */ `
  query ListRestaurants(
    $id: ID
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRestaurants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        address
        businessHour {
          sun {
            start
            end
            __typename
          }
          mon {
            start
            end
            __typename
          }
          tue {
            start
            end
            __typename
          }
          wed {
            start
            end
            __typename
          }
          thu {
            start
            end
            __typename
          }
          fri {
            start
            end
            __typename
          }
          sat {
            start
            end
            __typename
          }
          __typename
        }
        description
        imageUrl
        name
        rating
        createdAt
        updatedAt
        menuSections {
          items {
            id
            description
            name
            restaurantID
            createdAt
            updatedAt
            menuItems {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRestaurants = /* GraphQL */ `
  query SyncRestaurants(
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRestaurants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        address
        businessHour {
          sun {
            start
            end
            __typename
          }
          mon {
            start
            end
            __typename
          }
          tue {
            start
            end
            __typename
          }
          wed {
            start
            end
            __typename
          }
          thu {
            start
            end
            __typename
          }
          fri {
            start
            end
            __typename
          }
          sat {
            start
            end
            __typename
          }
          __typename
        }
        description
        imageUrl
        name
        rating
        createdAt
        updatedAt
        menuSections {
          items {
            id
            description
            name
            restaurantID
            createdAt
            updatedAt
            menuItems {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const menuItemsByMenusectionsID = /* GraphQL */ `
  query MenuItemsByMenusectionsID(
    $menusectionsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemsByMenusectionsID(
      menusectionsID: $menusectionsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        imageUrl
        menusectionsID
        name
        price
        createdAt
        updatedAt
        menuItemOptions {
          items {
            id
            description
            maximumSelectionCount
            menuitemID
            name
            requiredSelectionCount
            selections {
              additionalCost
              name
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const menuItemOptionsByMenuitemID = /* GraphQL */ `
  query MenuItemOptionsByMenuitemID(
    $menuitemID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuItemOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuItemOptionsByMenuitemID(
      menuitemID: $menuitemID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        maximumSelectionCount
        menuitemID
        name
        requiredSelectionCount
        selections {
          additionalCost
          name
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const menuSectionsByRestaurantID = /* GraphQL */ `
  query MenuSectionsByRestaurantID(
    $restaurantID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelMenuSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    menuSectionsByRestaurantID(
      restaurantID: $restaurantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        description
        name
        restaurantID
        createdAt
        updatedAt
        menuItems {
          items {
            id
            description
            imageUrl
            menusectionsID
            name
            price
            createdAt
            updatedAt
            menuItemOptions {
              nextToken
              startedAt
              __typename
            }
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
