import './LoginModal.css';
import siteLogo from "../assets/siteLogo.png";
import { FaGoogle, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { useUser } from '../UserContext';
import React, { useState } from "react";
import { signUp, confirmSignUp, signIn, signInWithRedirect, resetPassword, confirmResetPassword } from '@aws-amplify/auth';
import { message } from 'antd';

const LoginModal = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [codeSent, setCodeSent] = useState(false);
    const [signUpClicked, setSignUpClicked] = useState(false);
    const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false); // New state for forgot password
    const [newPassword, setNewPassword] = useState('');
    const [error, setError] = useState<string | null>(null);

    const { showLoginModal, setShowLoginModal } = useUser();

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    // Function to handle forgot password request
    const handleForgotPassword = async () => {
        try {
            await resetPassword({
                username: email
            });
            setCodeSent(true); // Proceed to code entry step
        } catch (error) {
            setError('Failed to send reset code. Please check your email.');
            console.error(error);
        }
    };

    // Function to reset the password with the code
    const handleForgotPasswordSubmit = async () => {
        try {
            await confirmResetPassword({
                username: email,
                confirmationCode: verificationCode,
                newPassword: newPassword
            });
            setForgotPasswordClicked(false); // Go back to sign-in view
            setCodeSent(false); // Reset states
            setError(null); // Clear any errors
        } catch (error) {
            setError('Failed to reset password. Please check your code and try again.');
            console.error(error);
        }
    };

    // Function to sign up a new user
    const signUpUser = async () => {
        try {
            await signUp({
                username: email,
                password: password,
                options: {
                    userAttributes: {
                        name: name
                    }
                }
            });
            setCodeSent(true); // Proceed to the code verification step
        } catch (error) {
            setError('Failed to sign up. Please check your email or password.');
            console.error(error);
        }
    };

    // Function to confirm the sign-up with the verification code
    const confirmSignUpWithCode = async () => {
        try {
            await confirmSignUp({
                username: email,
                confirmationCode: verificationCode
            });
            await signInUser(); // Automatically sign in after successful verification
        } catch (error) {
            setError('Invalid verification code. Please try again.');
            console.error(error);
        }
    };

    // Function to sign in the user after verification
    const signInUser = async () => {
        try {
            await signIn({
                username: email,
                password: password
            });
            setShowLoginModal(false); // Close the modal on successful sign-in
        } catch (error) {
            setError('Failed to sign in. Please check your credentials.');
            console.error(error);
        }
    };

    const loginWithGoogle = async () => {
        try {
            await signInWithRedirect({ provider: 'Google' });
        } catch (error) {
            setError("Failed to sign in with Google.");
            console.error(error);
        }
    }

    return (
        <>
            {showLoginModal && (
                <div className="login-modal-overlay">
                    <div className="login-modal">
                        <div className="login-modal-header">
                            <img src={siteLogo} alt="Site Logo" />
                            <ImCross className="login-modal-close" onClick={() => setShowLoginModal(false)} />
                        </div>
                        {error && <div className="login-error">{error}</div>}

                        {/* Forgot Password Workflow */}
                        {forgotPasswordClicked ? (
                            !codeSent ? (
                                <>
                                    <div className="email-input">
                                        <input
                                            type="email"
                                            placeholder="Enter Email"
                                            value={email}
                                            onChange={handleEmailChange}
                                        />
                                    </div>
                                    <button onClick={handleForgotPassword}>Send Reset Code</button>
                                    <div className="login-signup">
                                        <a onClick={() => setForgotPasswordClicked(false)}>Back to Sign In</a>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        placeholder="Enter Verification Code"
                                        value={verificationCode}
                                        onChange={(e) => setVerificationCode(e.target.value)}
                                    />
                                    <input
                                        type="password"
                                        placeholder="Enter New Password"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                    <button onClick={handleForgotPasswordSubmit}>Reset Password</button>
                                </>
                            )
                        ) : (
                            <>
                                {!codeSent ? (
                                    <>
                                        {signUpClicked ? (
                                            <div className="name-input">
                                            <input
                                                type="text"
                                                placeholder="Enter Name"
                                                value={name}
                                                onChange={handleNameChange}
                                            />
                                        </div>
                                        ) : ( <></> )}
                                        <div className="email-input">
                                            <input
                                                type="email"
                                                placeholder="Enter Email"
                                                value={email}
                                                onChange={handleEmailChange}
                                            />
                                        </div>
                                        <div className="password-input">
                                            <input
                                                type="password"
                                                placeholder="Enter Password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                            />
                                        </div>
                                        {signUpClicked ? (
                                            <button onClick={signUpUser}>Sign Up</button>
                                        ) : (
                                            <button onClick={signInUser}>Sign In</button>
                                        )}
                                        <div className="login-signup">
                                            <a onClick={() => setForgotPasswordClicked(true)}>Forgot your password?</a>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <input
                                            type="text"
                                            placeholder="Enter Verification Code"
                                            value={verificationCode}
                                            onChange={(e) => setVerificationCode(e.target.value)}
                                        />
                                        <button onClick={confirmSignUpWithCode}>Verify Code</button>
                                    </>
                                )}
                                <div className="login-divider">
                                    <span>OR</span>
                                </div>
                                <div className="login-social">
                                    <FaGoogle className="social-button" onClick={loginWithGoogle} />
                                    <FaFacebookF className="social-button" onClick={() => message.error("This button is not implemented. Please use Google or click Sign Up")} />
                                    <FaLinkedinIn className="social-button" onClick={() => message.error("This button is not implemented. Please use Google or click Sign Up")} />
                                </div>
                                <div className="login-signup">
                                    {signUpClicked ? (
                                        <div>
                                            Already a member? <a onClick={() => setSignUpClicked(false)}>Sign In</a>
                                        </div>
                                    ) : (
                                        <div>
                                            New member? <a onClick={() => setSignUpClicked(true)}>Sign Up</a>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}


                    </div>
                </div>
            )}
        </>
    );
};

export default LoginModal;
