import React, { forwardRef, Ref } from 'react';
import Item from './Item'; // Make sure to create this component
import './MenuSection.css'; // Assuming a separate CSS file for styling
import { MenuItem } from '../models';

// Define the props interface
interface MenuSectionProps {
    id: string;
    title: string;
    items: MenuItem[];
    onItemSelect: (item: MenuItem) => void;
}


const MenuSection = forwardRef<HTMLOptionElement, MenuSectionProps>(({
    id,
    title,
    items,
    onItemSelect
}, ref) => {
    return (
        <section ref={ref} id={id} className="menu-section">
            <h3 className="text-2xl font-semibold">{title}</h3>
            <div className="flex flex-wrap" id="items-container">
                {items.map((item) => (
                    <Item key={item.id} item={item} items={items} onItemSelect={onItemSelect} />
                ))}
            </div>
        </section>
    );
});

export default MenuSection;
