import { Elements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const Payment = () => {
    const stripePromise = loadStripe('pk_test_51Oplw4IogR4j3vVVdSTe6ZuMu6nFBe1ZjJAr4N7V5QdBSoLpq5KpFZ9YLFXNvO0BYGewqdtuXDzJRnCPpmOggHkC00VJq6pRSA');
    const options = {
        // passing the client secret obtained from the server
        clientSecret: 'pi_3Oqr0lIogR4j3vVV2kDr2bM3_secret_ZqZNcjVmDv2kPMPvCjNviSM86',
    };
    return (
        <div className='py-2 border-b pb-4'>
            <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold mb-2">Payment</h3>
            </div>
            <Elements stripe={stripePromise} options={options}>
                <form>
                    <PaymentElement />
                </form>
            </Elements>
        </div>
    );
}

export default Payment;