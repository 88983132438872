import React, { useEffect } from 'react';
import { useUser } from '../UserContext';
import { IoRestaurantOutline } from "react-icons/io5";
import { FiMapPin } from "react-icons/fi";
import { FaRegClock } from "react-icons/fa";
import { useRestaurantStore } from "../api/graphqlClient"
const RestaurantInfo = () => {
    const { restaurant, fetchRestaurant, loading, error } = useRestaurantStore();
    useEffect(() => {
        fetchRestaurant();
    }, [fetchRestaurant]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    const getDayOfWeek = () => {
        const daysOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
        const today = new Date();
        return daysOfWeek[today.getDay()];
    };

    const formatTime = (time) => {
        const [hour, minute] = time.split(":");
        const hourInt = parseInt(hour);
        const period = hourInt >= 12 ? "PM" : "AM";
        const formattedHour = hourInt % 12 || 12; // Convert 0 to 12 for midnight
        return `${formattedHour}:${minute} ${period}`;
    };

    const getBusinessHour = () => {
        const today = getDayOfWeek();
        const timeWindows = restaurant.businessHour[today];

        return timeWindows
            .map(window => `${formatTime(window.start)} - ${formatTime(window.end)}`)
            .join(", ");
    };

    return (
        <>
            <div className="max-w-2xl mx-auto ">

                <div className="max-w-2xl mx-auto">
                    <div className="flex justify-between items-center py-2">
                        <h3 className="text-lg font-semibold">Restaurant info</h3>
                    </div>
                </div>

                <div className="max-w-2xl mx-auto py-4 flex flex-col space-y-2 border-gray-300">
                    <div className="flex items-center space-x-2 text-gray-700">
                        <IoRestaurantOutline className="text-lg" />
                        <span>{restaurant.name}</span>
                    </div>
                    <div className="flex items-center space-x-2 text-gray-700">
                        <FiMapPin className="text-lg" />
                        <span>Pick up at {restaurant.address}</span>
                    </div>
                    <div className="flex items-center space-x-2 text-gray-700">
                        <FaRegClock className="text-lg" />
                        <span>{getBusinessHour()}</span>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RestaurantInfo;