import { useRestaurantStore } from "../api/graphqlClient"
function GoogleMapEmbed() {
    const { restaurant } = useRestaurantStore();

    return (
        <iframe
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD6AVIlqa6LZ4iQeLnQGr4dc4yp19a46eI&q=${restaurant.name.replace(/ /g, "+")}`}
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="w-full h-32 mb-8"
        ></iframe>
    );
};
export default GoogleMapEmbed;