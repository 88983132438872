// Header.tsx
import React from 'react';
import { useCart } from '../CartContext';
import { FaShoppingCart, FaUserCircle } from 'react-icons/fa';
import Cart from './Cart';
import { useUser } from '../UserContext';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import { useState, useEffect } from 'react';

import { signOut } from 'aws-amplify/auth';
import { useRestaurantStore } from '../api/graphqlClient';
import { LazyRestaurant, Restaurant } from '../models';

const Header: React.FC = () => {
    const { toggleCartVisibility, cartItems, totalItems } = useCart();
    const { restaurant, fetchRestaurant, loading, error } = useRestaurantStore();
    const { setShowLoginModal, isLoggedIn, signOutAction } = useUser();


    const items: MenuProps['items'] = [
        {
            label: 'Sign Out',
            key: '1',
            icon: <UserOutlined />,
        }
    ];

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        signOutAction();
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <header className="flex flex-col items-stretch">
            <div className="flex justify-between items-center p-4 bg-gray-100 shadow-md">
                <div className="flex items-center">
                    <div className='w-12 h-12 rounded-full overflow-hidden mr-4'>
                        <img
                            src={`https://quicksnackz-static-images.s3.us-west-1.amazonaws.com/${restaurant?.id}-logo.jpg`}
                            alt={`${restaurant?.name} logo`}
                            className="w-full h-full object-cover"
                        />

                    </div>
                </div>
                <div className="flex items-center">
                    {!isLoggedIn ? (
                        <FaUserCircle className="ml-5 cursor-pointer" size="1.5em" onClick={() => setShowLoginModal(true)} />
                    ) : (
                        <Dropdown menu={menuProps}>
                            <FaUserCircle className="ml-5 cursor-pointer" size="1.5em" />
                        </Dropdown>
                    )}
                    <div className="relative ml-2 cursor-pointer" onClick={toggleCartVisibility}>
                        <FaShoppingCart size="1.5em" />
                        {cartItems.length > 0 && (
                            <span className="absolute top-[-10px] right-[-10px] bg-blue-600 text-white rounded-full w-5 h-5 flex items-center justify-center text-xs">
                                {totalItems}
                            </span>
                        )}
                    </div>
                    <Cart />
                </div>
            </div>
            <div
                className="h-24 bg-cover bg-center bg-opacity-80"
                style={{
                    backgroundImage: `url("https://quicksnackz-static-images.s3.us-west-1.amazonaws.com/${restaurant?.id}-bg.jpeg")`
                }}
            >

                <div className="flex justify-center items-center h-full bg-gradient-to-t from-gray-900 to-transparent">
                    <h1 className="text-white text-3xl font-light">{restaurant?.name}</h1>
                </div>
            </div>
        </header>
    );
};

export default Header;
