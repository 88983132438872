// Navigation.jsx
import React, { useState, useEffect, useRef, RefObject } from "react";
import PropTypes from 'prop-types';
import './Navigation.css';
import { MenuSection, LazyMenuSection } from '../models';


// Define the types for the props
interface NavigationProps {
    sections: MenuSection[];
    scrollToRef: (index: number, offset: number) => void;
    menuSectionRefs: RefObject<HTMLOptionElement>[];
}


const Navigation: React.FC<NavigationProps> = ({ sections, scrollToRef, menuSectionRefs }) => {

    const navBarHeight = 60; // This is calculated and updated based on CSS
    const [selectedSectionId, setSelectedSectionId] = useState<string | null>("");
    const sectionRefs = useRef([]);

    // Initialize the sectionRefs array to store refs for each section
    useEffect(() => {
        sectionRefs.current = sections.map((_, i) => sectionRefs.current[i] ?? React.createRef());
        setSelectedSectionId(sections && sections[0] ? sections[0].id : "")
    }, [sections]);


    // Function to get the current section in view
    const getCurrentSectionInView = () => {
        const scrollPosition = window.scrollY + 360;
        for (let i = sections.length - 1; i >= 0; i--) {
            const sectionRef = menuSectionRefs[i].current;
            if (sectionRef) {
                const sectionTop = sectionRef.offsetTop;
                const sectionBottom = sectionTop + sectionRef.offsetHeight;
                if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                    return sections[i].id;
                }
            }
        }
        return null; // Return null if no section is in view
    };



    // Function to handle scroll events
    const handleScroll = () => {
        const currentSectionInView = getCurrentSectionInView();
        if (currentSectionInView !== selectedSectionId) {
            setSelectedSectionId(currentSectionInView);
        }
    };

    // Attach scroll event listener on component mount
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [selectedSectionId]); // Add selectedSectionId to dependencies to update when it changes

    const handleNavItemSelect = (sectionId: string, index: number) => {
        // const sectionRef = sectionRefs.current[index].current;
        scrollToRef(index, navBarHeight);
        setSelectedSectionId(sectionId);
    };

    return (
        <nav className="navigation w-full">
            <ul className="nav-menu flex-wrap">
                {sections.map((section, index) => (
                    <li
                        key={index}
                        ref={sectionRefs.current[index]}
                        className={selectedSectionId === section.id ? "nav-item nav-item-selected" : "nav-item"}
                        onClick={() => handleNavItemSelect(section.id, index)}
                    >
                        {section.name}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default Navigation;
